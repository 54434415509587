// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import 'bootstrap';
import "../stylesheets/application.scss";

// Ensure Bootstrap's JavaScript is available
import * as bootstrap from 'bootstrap';

// Import the addFields JavaScript file for add/remove nested fields functionality
import 'packs/nested-fields/addFields';
import 'packs/nested-fields/removeFields';

document.addEventListener("turbolinks:load", () => {
  // console.log('Turbolinks loaded - initializing Bootstrap components');
  // Initialize Bootstrap components (for Bootstrap 5)
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  });

  const dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
  const dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    return new bootstrap.Dropdown(dropdownToggleEl)
  });
});

// Remove jQuery related code, as Bootstrap 5 does not require it